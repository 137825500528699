import { AxiosError } from 'axios';
import ApiService from '../api-service';
import { UserInfo, UserBaseInfo } from '@/common/models/user';
import { UpdatePassword } from '@/common/models/profile';

export default class ZbProfileService extends ApiService {
  async getProfileData(
    userId: number
  ): Promise<{ statusCode: number; data: UserInfo | undefined; message: string }> {
    try {
      const value = await this.axios.get(`/users/${userId}`);
      return { statusCode: value.status, data: value.data, message: '' };
    } catch (error) {
      const e = error as AxiosError;
      if (e.response?.data) {
        return { statusCode: e.response.status, data: undefined, message: e.response.data };
      }
      return { statusCode: -1, data: undefined, message: 'Interner Fehler' };
    }
  }

  async saveProfileData(
    userInfo: UserInfo
  ): Promise<{ statusCode: number; data: UserInfo | undefined; message: string }> {
    try {
      const value = await this.axios.patch(`/users/${userInfo.id}`, userInfo);
      return {
        statusCode: value.status,
        data: value.data,
        message: 'Benutzer informationen aktualisiert',
      };
    } catch (error) {
      const e = error as AxiosError;
      if (e.response?.data) {
        return { statusCode: e.response.status, data: undefined, message: e.response.data };
      }
      return { statusCode: -1, data: undefined, message: 'Interner Fehler' };
    }
  }

  async saveProfileEmail(
    userInfo: UserBaseInfo
  ): Promise<{ statusCode: number; data: UserInfo | undefined; message: string }> {
    try {
      const value = await this.axios.patch(`/users/${userInfo.id}`, userInfo);
      return {
        statusCode: value.status,
        data: value.data,
        message: 'Benutzer E-Mail aktualisiert',
      };
    } catch (error) {
      const e = error as AxiosError;
      if (e.response?.data) {
        return { statusCode: e.response.status, data: undefined, message: e.response.data };
      }
      return { statusCode: -1, data: undefined, message: 'Interner Fehler' };
    }
  }

  async updateUserPassword(
    userId: number,
    updatesPassword: UpdatePassword
  ): Promise<{ statusCode: number; message: string }> {
    try {
      const value = await this.axios.put(`users/${userId}/password`, updatesPassword);
      return { statusCode: value.status, message: 'Benutzer Passwort aktualisiert' };
    } catch (error) {
      const e = error as AxiosError;
      if (e.response?.data) {
        if (e.response.data.old_password) {
          return {
            statusCode: e.response.status,
            message: e.response.data.old_password.old_password,
          };
        }
        if (e.response.data.password) {
          return {
            statusCode: e.response.status,
            message: e.response.data.password[0],
          };
        }
      }
      return { statusCode: -1, message: 'Interner Fehler' };
    }
  }
}
